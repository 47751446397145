.language-selection {
    background-image: url('../Images/country-welle.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    height: 90vh;

    h1, h2, h3 {
        color: $text-red;
    }

    h1 {
        position: relative;
        padding-top: 2rem;
        margin-bottom: 2rem;
        font-size: 2rem;

        @include media-breakpoint-up(lg) {
            font-size: 4rem;
        }

        @include media-breakpoint-up(lg) {
            &:before {
                content: '+';
                position: absolute;
                top: -12px;
                left: -48px;
            }
        }
    }

    &-options {
        margin-top: 2rem;
    }

    &-list {
        margin-top: 1rem;
        margin-bottom: 2rem;
        list-style: none;
        padding-left: 0;

        a {
            color: black;
        }
    }

    &-bg {
        height: 100%;
        background-image: url('../Images/map.png');
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center bottom;
    }
}

