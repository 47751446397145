.tools {
    color: $text-red;

    padding-top: $default-spacing;
    padding-bottom: $default-spacing;

    @include media-breakpoint-up(lg) {
        padding-top: $default-spacing-lg;
        padding-bottom: $default-spacing-lg;
    }

    &-header {
        text-align: center;
        margin-bottom: 2rem;
        font-weight: 500;
    }

    &-title {
        font-size: $h3-font-size;
    }

    &-subtitle {
        font-size: $h5-font-size;
        @include media-breakpoint-up(lg) {
            min-height: 35px;
        }
        margin-bottom: 2rem;
    }

    &-link {
        @include btn-red;
    }

    &-nolink {
        @include media-breakpoint-up(lg) {
            display: block;
            min-height: 30px;
        }
    }

    &-img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}
