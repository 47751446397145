.link-block {
    padding-top: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
        padding-top: $default-spacing-lg;
        padding-bottom: $default-spacing-lg;
    }

    &-header {
        text-align: center;
        margin-bottom: 2rem;
        font-weight: 500;
        color: $text-red;
    }

    &-title {
        font-size: $h3-font-size;
        color: $text-red;
        margin: 1.5rem 0;
        padding: 0 1rem;
    }

    &-img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    &-content {
        position: relative;
        width: 100%;
        padding-bottom: 3rem;
        margin-bottom: 1rem;
        margin-top: 1rem;


        @include media-breakpoint-up(lg) {
            height: 100%;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    p {
        padding: 0 1rem;
        margin-bottom: 2rem;
    }

    &-link {
        @include btn-red;

        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
        }
    }

    &-bg-1 {
        background-color: $light-bg-color;
    }

    &-bg-2 {
        box-shadow: 0 3px 11px 0 rgba(0,0,0,0.3);
        transition: box-shadow 0.3s ease-in-out;
        border-radius: 5px;

        &:hover {
            box-shadow: 0 3px 20px 0 rgba(0,0,0,0.5);
        }

        .link-block-img {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
}
