.color-blocks {
    color: white;
    margin: 4rem 0;

    &-color {
        padding: 1rem;
        &-0 {
            background-color: $text-red;
        }

        &-1 {
            background-color: $gray;
        }

        &-2 {
            background-color: $gray-light;
        }
    }

    &-title {
        font-size: $h3-font-size;
    }

    &-icon {
        max-width: 100%;
        height: auto;
        display: inline-block;
        margin-left: 1rem;
    }

    &-link {
        color: white;
        border-radius: 25px;
        border: 1px solid white;
        text-decoration: none;
        padding: 3px 10px;
        display: inline-block;
    }
}
