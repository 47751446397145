@mixin btn-red {
    display: inline-block;
    background-color: $text-red;
    color: white;
    border-radius: 25px;
    padding: 0 25px;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        background-color: darken($text-red, 5%);
        color: white;
    }
}
