.counter {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-number {
        text-align: center;
        font-size: 45px;
        color: $text-red;
    }

    &-label {
        text-align: center;
        font-size: 25px;
    }
}

.timer {
    display: flex;
    justify-content: center;
    font-size: 4rem;
    font-weight: 300;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    b {
        font-weight: 100;
        line-height: 1;
        display: flex;
        justify-content: center;
        flex-flow: column;
        padding: 16px 64px;

    }

    &-label {
        font-size: .8rem;
    }
}
