.text-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
        width: auto;
        text-align: center;
        color: $white;

        h2 {
            color: $white;
        }
    }

    &-small {
        min-height: 40vh;
        padding: 4rem 0;

        .text-background-content {
            text-align: left;
        }
    }
}

.two-cols-text {
    h2 {
        margin-top: 2rem;
    }

    &-left {
        @include media-breakpoint-up(lg) {
            padding-right: 0.75rem;
        }
    }

    &-right {
        @include media-breakpoint-up(lg) {
            padding-left: 0.75rem;
        }
    }

    &-caption {
        &-slider {
            bottom: 5px;
            color: #f5f5f5;
            position: absolute;
            right: 15px;
            text-shadow: 0 0 3px #000;
        }
    }

    .swiper-pagination-bullets {
        left: 10px !important;
        text-align: left;
        width: 30% !important;
    }
}

.img-text {
    padding-bottom: 1.5rem;

    &-space {
        &-1 {
            padding-bottom: $default-spacing-lg;
        }
    }

    &-header {
        margin-top: $default-spacing-lg;
        margin-bottom: 5rem;
    }

    &-text {
        padding: 1rem;
        @include media-breakpoint-up(md) {
            padding: 5rem;
        }

        color: $text-gray-dark;
        background-color: white;

        @include media-breakpoint-up(lg) {
            background-color: transparent;
        }
    }

    &-col {
        @include media-breakpoint-up(lg) {
            padding-right: 1.5rem;
        }
    }


    &-container {
        background-color: transparent;

        @include media-breakpoint-up(lg) {
            background-color: white;
        }
    }

    &-img {
        background-color: $red;
        height: 100%;
        color: white;

        &-title {
            padding: 1rem;
            font-weight: 600;
            font-size: 30px;
            line-height: 30px;

            @include media-breakpoint-up(md) {
                padding: 2rem 4rem;
            }
        }
    }
}

