.consultant {
    margin-top: 2rem;
    margin-bottom: 2rem;

    &-main-header {
        margin-top: 3rem;
        text-align: center;
        color: $text-red;
        margin-bottom: 0;
    }

    &-slogan {
        text-align: center;
        color: $text-red;
        margin-bottom: 3rem;
    }

    &-img-col {
        padding-right: 0;
    }

    &-bg {
        background-color: $light-bg-color;
        padding-bottom: 1rem;
        padding-top: 1rem;
        border-style: solid;
        border-width: 1px;
        border-color: $gray-2;
    }

    &-details {
        margin-top: 1rem;
    }
    &-name {
        color: $text-red;
        margin-bottom: 3px;
    }
}
