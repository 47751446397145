.detail-text {
    &-header {
        color: $text-red;
        margin-bottom: 1rem;
    }

    &-image {
        display: block;
        max-width: 100%;
        height: auto;
        margin-bottom: 1rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
}
