.faq-link {
    background-color: $light-bg-color;
    border-radius: 160px;
    padding: 2rem;
    margin: 4rem auto;

    @include media-breakpoint-up(lg) {
        max-width: 65%;
    }

    &-header {
        color: $text-red
    }

    &-subheader {
        color: $gray;
        margin: 1rem 0;
    }

    &-container {
        width: 50%;
        align-content: center;
        justify-content: center;
    }

    &-content {
        width: 50%;
    }

    &-faq {
        line-height: 130px;
        color: $text-red;
        font-size: 100px;
    }

    &-link {
        @include btn-red;
    }
}

.faq {
    border-bottom: 1px solid $text-red;
    margin: 2rem 0;

    &-question {
        font-size: $h3-font-size;
        color: $text-red;
        text-decoration: none;
        display: block;
        position: relative;

        &:after {
            content: '+';
            position: absolute;
            right: -3px;
            display: inline-block;
            transform: rotate(45deg);
            transition: all .2s ease-in-out;
            font-weight: 400;
            font-size: $h3-font-size;
        }
    }

    .collapsed {
        &:after {
            transform: rotate(0);
            right: 0;
        }
    }


    &-answer {
        margin: 2rem 0 0 0;
    }
}
