.icon-grid {
    &-container {
        border-top: 1px solid $text-red;
        border-bottom: 1px solid $text-red;
        display: flex;
        height: 225px;
        margin-bottom: 2rem;
    }

    &-icon {
        width: 50%;
        height: auto;
        margin: 2rem 4rem;
        display: inline-block;
    }

    &-text {
        margin: 2rem 0;
        width: 50%;
    }
}
