.download-list {
    &-header {
        color: $text-red;
        text-transform: uppercase;
        display: block;
        border-bottom: 1px solid $text-red;
        padding: 1rem 0;
    }

    &-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: center;
    }

    &-col {
        flex: 1 250px;
    }

    &-file {
        padding: 1rem 0;
        display: inline-block;
        text-decoration: none;

        &-small {
            padding: .2rem 0;
            font-size: 1rem;
        }

        &-thumb {
            display: inline-block;
            width: 100%;
            max-width: 200px;
            height: auto;
            margin-bottom: 1rem;
            box-shadow: 1px 6px 19px -6px rgba(0,0,0,0.75);
        }

        &-video {
            min-width: 20%;
        }

        &-title {
            color: $gray-light;
            display: block;
            font-size: 1rem;
        }

        &-description {
            color: $gray-light;
            display: block;
            font-size: 1rem;
        }
    }

    &-video-container {
        display: block;
    }

    &-border {
        margin-bottom: 2rem;
    }
}
