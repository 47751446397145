.benefits {
    color: $text-red;

    &-header {
        margin-bottom: 3rem;
    }

    &-image {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 15px auto;

        @include media-breakpoint-up(lg) {
            margin: 5px auto;
        }
    }

    &-text {
        display: flex;
        align-items: center;
        height: 100%;

        p {
            margin: 0;
        }
    }
}
