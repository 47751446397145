.product-list {
    margin-bottom: 3rem;
    // needed for .product-list-details
    position: relative;

    &-header {
        color: $text-red;
        text-align: center;
        margin-bottom: 2rem;

        &-mt {
            margin-top: 2rem;
        }
    }

    &-item {
        display: flex;
        background-color: $white;
        padding: 1rem;
        text-decoration: none;
        position: relative;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    &-title {
        display: block;
        font-size: $h3-font-size;
        font-weight: $font-weight-bold;
        color: $red;
        text-align: center;
        z-index: 100;

        &-small {
            text-align: center;
            color: $red;
            font-size: $h6-font-size;
            font-weight: $font-weight-bold;
            z-index: 100;
        }
    }

    &-teaser {
        color: var(--bs-body-color);
        flex-grow: 1;
    }

    &-image {
        bottom: 0;
        height: auto;
        max-width: calc(100% + 2rem);
        width: calc(100% + 2rem);
        margin-left: calc(-1rem);
        margin-right: calc(-1rem);
        transition: all 0.5s ease-in-out;
        //margin-top: -7rem;
        z-index: 99;
    }

    &-details {
        position: absolute;
        bottom: 2rem;
        left: 2rem;
        z-index: 200;
    }

    &-power {
        background-color: white;
        color: var(--bs-body-color);
        border-radius: 25px;
        padding: 5px 10px;
        display: block;
        margin-bottom: 10px;
    }

    &-icons {
        display: block;
    }

    &-icon {
        max-width: 100%;
        height: auto;
        display: inline-block;
        margin-right: 5px;
    }

    &-cta {

    }
}

.header-product {
    height: 75vh;

    @media (orientation: landscape) {
        height: 100vh;
    }

    @media (orientation: landscape ) and (min-height: 510px) {
        height: 510px;
    }

    @include media-breakpoint-up(lg) {
        height: 530px;
    }

    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    color: $text-red;

    &-header {
        h1 {
            font-size: $header-font-size-mobile;
            @include media-breakpoint-up(lg) {
                font-size: $header-font-size;
            }
        }
        p {
            font-size: 25px;
            @include media-breakpoint-up(lg) {
                font-size: 35px;
            }
        }

        z-index: 10;
        position: absolute;
        left: 0;
        top: 130px;
        @include media-breakpoint-up(lg) {
            top: initial;
            bottom: 50px;
            left: 32px;
        }
    }

    &-container {
        height: 100%;
        position: relative;
    }

    &-details {
        display: none;

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    &-power {
        margin-right: 2rem;
        padding: 2px 0;
        color: $gray-dark;
    }

    &-btn {
        margin-right: 4rem;
        display: inline-block;
        background-color: $gray-dark;
        color: white;
        border-radius: 20px;
        padding: 2px 15px;
        text-decoration: none;

        &:hover {
            color: white;
        }
    }

    &-icon {
        margin-right: 1rem;
    }

    &-image {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 270px;
        height: auto;
        z-index: 3;

        @include media-breakpoint-up(lg) {
            max-width: 350px;
        }
        @include media-breakpoint-up(xl) {
            max-width: initial;
        }

        @media (orientation: portrait) {
            @include media-breakpoint-up(sm) {
                max-width: initial;
            }
        }
    }
}

.lg-hotspot {
    position: absolute;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);
    z-index: 0;

    &__close {
        float: right;
        cursor: pointer;
    }

    &__label {
        position: absolute;
        padding: 0;
        width: 16em;
        max-width: 50vw;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: -1;
        pointer-events: none;
        border-radius: $labelBorderRadius;
        user-select: none;
        opacity: 0;
        transition: all $labelFade linear;
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
        @include media-breakpoint-up(xl) {
            width: 600px;
        }

        h4 {
            margin: 0;
            padding: 0.65em $labelPaddingX;
            background-color: $colorHeadingBackground;
            font-size: 1.1em;
            font-weight: normal;
            letter-spacing: 0.02em;
            color: white;
            border-radius: $labelBorderRadius $labelBorderRadius 0 0;
        }
        p {
            margin: 0;
            padding: 1.1em $labelPaddingX 1.1em $labelPaddingX;
            color: $colorText;
        }
    }
    &__button {
        height: $buttonRadius * 2;
        width: $buttonRadius * 2;
        padding: 0;
        border-radius: 100%;
        border: 3px solid $colorButton;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
        animation: button-pulse 2s ease-in-out infinite;
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            content: "";
            display: block;
            height: $buttonRadius * 0.7;
            width: $buttonRadius * 0.7;
            border-radius: 100%;
            border: 3px solid white;
            background-color: $colorButton;
            transition: border-color 1s linear;
        }
    }

    &--selected {
        z-index: 999;
        .lg-hotspot__label {
            @include media-breakpoint-up(md) {
                opacity: 1;
                pointer-events: auto;
                user-select: auto;
            }
        }
    }

    &:hover,
    &:active {
        .lg-hotspot__button {
            border-color: $colorButtonHover;
            &:after {
                background-color: $colorButtonHover;
            }
        }
    }
}

.hotspot {

    &-header {
        color: $text-red;
        margin-top: 2rem;
    }

    &-container {
        position: relative;
    }

    &-image {
        display: block;
        width: 100%;
        height: auto;
    }

    &-mobile-container {
        display: block;
        position: static;
        top: 0;

        .lg-hotspot__label {
            display: block !important;
            opacity: 1 !important;
            pointer-events: auto !important;
            user-select: auto !important;
            z-index: 1 !important;
            max-width: 100vw !important;
            width: 100% !important;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .modal-dialog {
        max-width: 800px;
    }

    &-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: center;

        &-link {
            flex: 1 250px;
            padding: 5px;
        }
    }
}


@keyframes button-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    75% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.product-page-nav {
    left: 0;
    position: absolute;
    top: 25px;

    @include media-breakpoint-up(lg) {
        left: 32px;
    }

    &-category {
        &-link {
            background-color: $text-red;
            border-radius: 15px;
            color: white;
            display: inline-block;
            padding: 2px 12px;
            text-decoration: none;
            &:hover {
                color: white;
            }
        }
    }

    &-item {

    }

    &-link {
        padding: 2px 12px;
        display: inline-block;
        text-decoration: none;
    }
}
