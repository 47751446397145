
.timeline {
    position: relative;

    &-block {
        &-placeholder {
            height: 300px;
        }

        &-last {
            height: 575px;
            background-color: $anniversary-bg;
            bottom: 0;
            width: 2px;
            position: absolute;
            left: 50%;
            margin-left: -2px;
        }

        .swiper-pagination {
            width: 30% !important;
            text-align: left;
            left: 10px !important;
        }

        max-width: 350px;
        @include media-breakpoint-up(xl) {
            max-width: 400px;
        }
    }

    &-year {
        position: absolute;

        &-label {
            max-width: 89px;
            width: 89px;
            display: inline-block;
            font-size: 25px;
            font-weight: 600;
            position: relative;
            color: $timeline-line-color;
            overflow-wrap: anywhere;
        }
    }

    &-container {
        position: relative;
        height: 600px;
    }

    &-line {
        border-top: 2px dotted $timeline-line-color;
        position: absolute;
        max-width: 175px;
        width: 175px;
        margin-top: 10px;
    }

    &-right {
        .timeline-block {
            position: absolute;
            right: 0;
        }

        .timeline-year {
            left: -135px;
            margin-top: -5px;
        }

        .timeline-line {
            left: 0;
        }

        .timeline-year-label:before {
            content: '';
            height: 25px;
            width: 25px;
            background-color: $timeline-line-color;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            right: -26px;
            top: 5px;
        }
    }

    &-left {
        border-right: 2px dotted $timeline-line-color;

        .timeline-year {
            text-align: right;
            right: -150px;
        }

        .timeline-year-label {
            text-align: left;

            &:before {
                content: '';
                height: 25px;
                width: 25px;
                background-color: $timeline-line-color;
                border-radius: 50%;
                display: inline-block;
                margin-left: -40px;
                margin-right: 10px;
                position: absolute;
                top: 5px;
            }
        }

        .timeline-line {
            right: 0;
            margin-top: 15px;
        }
    }

    &-image {
        position: relative;
    }

    &-caption {
        position: absolute;
        right: 5px;
        bottom: 5px;
        color: $gray-light;
        text-shadow: 0 0 3px #000000;
    }

}

.timeline-mobile {
    .swiper-pagination {
        width: 30% !important;
    }

    .swiper-pagination-bullet {
        background: white !important;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background: $red !important;
    }

    .img-fluid {
        width: 100%;
    }

    &-container {
        margin-bottom: $default-spacing;
    }

    &-year {
        &-label {
            font-weight: 600;
            font-size: 25px;
        }
    }
}

.swiper-button-prev, .swiper-button-next {
    color: $red !important;
}

.swiper-pagination-bullet {
    background: white !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background: $red !important;
}
