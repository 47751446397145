.teaser-intro-spacing {
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;

    @include media-breakpoint-up(lg) {
        padding-top: $default-spacing-lg;
        padding-bottom: $default-spacing-lg;
    }
}

.teaser-intro-link {
    p {
        color: $text-red;
        font-size: $subheader-font-size-mobile;

        @include media-breakpoint-up(lg) {
            font-size: $subheader-font-size;
        }
    }

    &-btn {
        display: inline-block;
        background-color: $text-red;
        color: white;
        border-radius: 25px;
        padding: 0 20px;
        text-decoration: none;
        margin-top: 1rem;

        &:hover {
            background-color: darken($text-red, 5%);
            color: white;
        }
    }
}

.teaser-intro {
    &-bg {
        &-0 {
            background: transparent;
        }

        &-1 {
            background-color: $light-bg-color;
        }
    }

    &-align {
        &-0 {
            text-align: center;
        }

        &-1 {
            text-align: left;
        }
    }

    &-header {
        color: $text-red;
        font-weight: 500;
    }

    p {
        margin: 2rem 0 0 0;
    }
}

.teaser-offer {
    &-header {
        margin-bottom: 2rem;
    }

    &-image {
        width: 100%;
        height: auto;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &-bg {
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: left top;

            @include media-breakpoint-down(lg) {
                background-image: none !important;
            }
            @include media-breakpoint-up(lg) {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-img-col {
        @include media-breakpoint-up(lg) {
            padding-right: 0;
        }
    }

    &-text-col {
        @include media-breakpoint-up(lg) {
            padding-left: 0;
        }
    }

    &-content {
        padding: 2rem 0;
        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }

        p {
            margin-bottom: 2rem;
        }
    }

    &-link {
        border-radius: 25px;
        display: inline-block;
        text-decoration: none;
        padding: 0.2rem 1rem;

        &:hover {
            text-decoration: none;
        }
    }
}

.teaser-offer-bg-0 {
    .teaser-offer-header {
        color: white;
    }

    .teaser-offer-text-col {
        @include media-breakpoint-up(lg) {
            background-color: $text-red;
        }
    }

    .teaser-offer-content {
        color: white;

        padding-left: 2rem;
        padding-right: 2rem;
        background-color: $text-red;
        @include media-breakpoint-up(lg) {
            background-color: transparent;
        }
    }

    .teaser-offer-link {
        background-color: white;
        color: $text-red;
    }
}

.teaser-offer-bg-1 {
    .teaser-offer-header {
        color: $text-red;
    }

    .teaser-offer-link {
        background-color: $text-red;
        color: white;
    }
}
