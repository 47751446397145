.powermail-col-break {
    @include media-breakpoint-up(md) {
        margin-right: 50% !important;
    }
}

.powermail_form {
    .form-control, .form-select {
        border-radius: 30px;
        background-color: $light-bg-color;
    }

    .form-select-lg {
        font-size: 1.2rem;
        padding: 0.9rem 1rem;
    }
}

.form-control, .form-select {
    font-family: sans-serif;
}

.powermail_fieldwrap_type_text {
    color: $text-red;
    font-weight: bold;
}

.powermail_fieldwrap_type_check {
    margin-bottom: 1rem;

    .checkbox {

        @include media-breakpoint-up(md) {
            display: inline-block;
            width: 50%;
        }
    }

    &.powermail-full-width {
        .checkbox {
            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }
    }
}

.powermail-container-wrapper {
    .container {
        padding: 0 !important;
    }
}

.contact-info {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid black;
    padding: 10px;
    border-radius: 5px;
    text-align: center;

    &-image {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

.form-floating>.form-select {
    padding-bottom: .2rem;
    padding-top: 1.625rem;
}
