.zoom {
    .zoom-in {
        border-radius: 5px;
    }

    .zoom-out {
        border-radius: 5px;
    }
}

.attribution {
    position: absolute;
    bottom: 0;
    right: 0;

    button {
        display: none;
    }

    ul {
        list-style: none;
    }
}

.map {
    height: 600px;
    width: 100%;
}

.title {
    text-align: center;
    margin: 50px;
}

.search {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &-results {
        padding-top: 110px;
    }

    .searchButton {
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}
