.news-list {
    &-item {
        margin: 2rem 0;
    }

    &-header {
        text-align: center;
        color: $text-red;
        margin-bottom: 2rem;
    }

    &-img {
        max-width: 100%;
        display: inline-block;
        height: auto;
    }

    &-category {
        display: block;
        font-size: $font-size-small;
        margin-bottom: 1rem;
        text-transform: uppercase;
        color: $gray-dark;
    }

    &-link {
        font-size: $h5-font-size;
        display: block;
        margin-bottom: 1rem;
        color: $gray-dark;
        text-decoration: none;

        &:hover {
            color: $text-red;
        }
    }

    &-teaser {
        color: $gray-dark;
        display: block;
        font-size: $font-size-small;
    }

    &-container {
        margin: 4rem auto;
    }

    &-more {
        border: 1px solid $gray-dark;
        border-radius: 25px;
        color: $gray-dark;
        text-decoration: none;
        padding: 3px 7px;
        display: inline-block;

        svg {
            margin-top: -3px;
        }
    }
}

.news-block-list {
    margin: 4rem auto;

    &-header {
        text-align: center;
        color: $text-red;
        margin-bottom: 2rem;
    }

    &-img {
        max-width: 100%;
        display: inline-block;
        height: auto;
    }

    &-category {
        display: block;
        font-size: $font-size-small;
        margin: 1rem 0;
        text-transform: uppercase;
        color: $gray-dark;
    }

    &-link {
        font-size: $h6-font-size;
        font-weight: 600;
        line-height: 1.4;
        margin-top: .5rem;
        display: block;
        margin-bottom: 0.5rem;
        color: $gray-dark;
        text-decoration: none;

        &:hover {
            color: $text-red;
        }
    }

    &-teaser {
        color: $gray-dark;
        display: block;
        font-size: $font-size-small;
        margin-bottom: 3rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    &-more {

    }
}

.blog-list {
    margin-top: 2rem;
    margin-bottom: 2rem;

    &-item {
        padding: 0 1rem ;
    }

    &-img {
        max-width: 100%;
        display: inline-block;
        height: auto;
        margin-top: 10px;
    }

    &-link {
        border-top: 1px solid $text-red;
        border-bottom: 1px solid $text-red;
        display: block;
        padding: 1rem 0;
        font-weight: $font-weight-bold;
        text-decoration: none;
    }
}

.blog-long-list {
    margin: 2rem;
    font-size: 1rem;

    a {
        text-decoration: none;
    }

    &-top-header {
        border-bottom: 1px solid $text-red;
        color: $text-red;
        margin: 3rem -2rem;
    }

    &-item {
        margin-bottom: 3rem;
        background-color: $light-bg-color;
    }

    &-img-col {
        min-height: 300px;

        @include media-breakpoint-up(lg) {
            min-height: 280px;
        }
    }

    &-date {
    }

    &-col {
        padding-top: 20px;
        padding-bottom: 20px;
        background-size: cover;
        background-position: center center;
    }

    &-header {
        font-weight: $font-weight-bold;
        font-size: 24px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}

.blog-category-list {
    list-style: none;
    padding: 0;
    font-size: 1rem;

    &-header {
        margin-top: 5rem;
        color: $text-red;
    }

    &-item {
        display: block;
        margin-bottom: 10px;
    }

    &-link {
        display: inline-block;
        background-color: $text-red;
        color: white;
        border-radius: 20px;
        text-decoration: none;
        padding: 5px 20px;

        &:hover {
            color: white;
        }
    }
}

.news-header {
    padding-bottom: 0.5rem;
    color: $text-red;
    border-bottom: 1px solid $text-red;
}

.related-news {
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    padding: 4rem 0;
    margin: 5rem 90px;
    position: relative;

    &-header {
        margin-bottom: 2rem;
    }

    .swiper-button-next {
        right: -40px;
    }
    .swiper-button-prev {
        left: -40px;
    }
}

.event-list {
    &-date {
        display: block;
        font-size: $h3-font-size;
        color: $gray;
        font-weight: $font-weight-bold;
    }

    &-teaser {
        color: $gray;
    }

    &-header {
        font-weight: $font-weight-bold;
        display: block;
        font-size: $h3-font-size;
    }
}
