
.img-carousel {
    padding-bottom: $default-spacing;

    @include media-breakpoint-up(lg) {
        padding-bottom: $default-spacing-lg;
    }

    &-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: center;

        &-link {
            flex: auto;
            padding: 5px;
        }

        &-4 {
            .img-carousel-gallery-link {
                width: 25%;
            }
        }

        &-3 {
            .img-carousel-gallery-link {
                width: 33.33%;
            }
        }

        &-2 {
            .img-carousel-gallery-link {
                width: 50%;
            }
        }

        &-1 {
            .img-carousel-gallery-link {
                width: 100%;
            }
        }
    }
}

.modal-dialog-lg {
    @include media-breakpoint-up(md) {
        max-width: 1000px !important;
    }
}
