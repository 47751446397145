
.job {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 1rem;
    background-color: $light-bg-color;

    &-detail {
        color: $text-red;
        text-decoration: none;
    }

    &-col {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        flex-direction: column;
    }

    &-label {
        font-size: 1rem;
        display: inline-block;
        margin-bottom: 0.5rem;
    }
}

.job-form {
    .form-control, .form-select {
        border-radius: 30px;
        background-color: $light-bg-color;
    }
}

.job-even {
    .job {
        background-color: $gray-3;
    }
}

.job-details  {
    tr {
        margin-bottom: 0.5rem;

        td:first-child {
            color: $text-red;
        }
    }
    td {
        vertical-align: top;
    }

    @include media-breakpoint-down(md) {
        tr td {
            display: block;
        }
    }
}
