.footer {
    background-color: $gray-dark;
    color: white;
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;
    font-size: 1.1rem;

    a {
        color: white;
        text-decoration: none;

        &:hover {
            color: white;
        }
    }

    &-link {
        display: block;
        &-first {
            font-weight: bold;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &-logo {
        margin-bottom: 4rem;
    }

    &-menu {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: auto;
        column-gap: 1rem;
        row-gap: 1rem;

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);
        }

        &-menu {

        }

        &-social {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            align-content: center;

            &-link {
                padding: 5px;
            }
        }
    }
}
