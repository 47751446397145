
.cta {
    background-color: $text-red;
    color: white;
    border-radius: 60px;
    padding: 2rem;
    margin: 4rem auto;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
        max-width: 65%;
        flex-direction: row;
        border-radius: 160px;
        text-align: left;
    }

    &-content {
        margin-left: 0;
        @include media-breakpoint-up(lg) {
            margin-left: 3rem;
        }
    }

    &-header {
        font-size: $h2-font-size;
    }

    &-btn {
        background-color: white;
        color: $black;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        text-decoration: none;
        margin-right: 1rem;
        margin-top: 1rem;
        display: inline-block;

        &:hover {
            text-decoration: none;
        }
    }
}
