@font-face {
    font-display: swap;
    font-family: DinPro;
    src: url("../fonts/DINPro-Regular.otf") format("opentype");
}

@font-face {
    font-display: swap;
    font-family: DinPro;
    font-weight: 600;
    src: url("../fonts/DINPro-Medium.otf") format("opentype");
}

@font-face {
    font-display: swap;
    font-family: Akkurat;
    src: url("../fonts/Akkurat.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: Akkurat;
    font-weight: 600;
    src: url("../fonts/AkkuratBold.woff") format("woff");
}
