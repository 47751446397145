@import "variables";
@import "mixins";
@import "bootstrap";
@import "fonts";
@import "content";
@import "buttons";
@import "header";
@import "teaser";
@import "headline";
@import "cta";
@import "tools";
@import "link_block";
@import "faq";
@import "footer";
@import "news";
@import "color_blocks";
@import "text_background";
@import "carousel";
@import "timeline";
@import "downloads";
@import "text_list";
@import "icon_grid";
@import "products";
@import "references";
@import "tt_address";
@import "benefits";
@import "detail_text";
@import "form";
@import "counter";
@import "job";
@import "consultant";
@import "language_select";
@import "carousel";
@import "chart";
@import "~swiper/swiper-bundle";
@import "~klaro/src/scss/klaro";
@import '~aos/src/sass/aos';

// fix timeline background color for main site if 100 jahre page is removed move this to _timeline.scss
.timeline-block-last {
    background-color: white !important;
}

button.service-btn {
    position:fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: $red;
    border: 1px solid $colorDarkGray;
    border-radius: 5px;
    color: $white;
    display: inline-block;
    margin: 0;
    padding: .5rem 0.8rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color .2s linear;

    &:hover {
        background-color: $red;
        border: 1px solid $red;
    }
}
