.headline {
    text-align: center;
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;

    &-header {
        color: $text-red;
        margin-bottom: 3rem;
        font-weight: 500;
    }

    &-content {
        margin-bottom: 1rem;

        h3, h4, h5, h6 {
            margin-bottom: 2rem;
            color: $text-red;
            font-weight: 500;
        }

        &-icon {
            width: 33%;
            max-width: 100%;
            display: inline-block;
            height: auto;
            margin-bottom: 2rem;
        }
    }
}

.bg-4 {
    .headline-content {
        h3, h4, h5, h6 {
            color: $white;
        }
    }
}
