@use "sass:math";

// Scales for responsive SVG containers
$ct-scales: ((1), math.div(15, 16), math.div(8, 9), math.div(5, 6), math.div(4, 5), math.div(3, 4), math.div(2, 3), math.div(5, 8), math.div(1, 1.618), math.div(3, 5), math.div(9, 16), math.div(8, 15), math.div(1, 2), math.div(2, 5), math.div(3, 8), math.div(1, 3), math.div(1, 4)) !default;
$ct-scales-names: (ct-square, ct-minor-second, ct-major-second, ct-minor-third, ct-major-third, ct-perfect-fourth, ct-perfect-fifth, ct-minor-sixth, ct-golden-section, ct-major-sixth, ct-minor-seventh, ct-major-seventh, ct-octave, ct-major-tenth, ct-major-eleventh, ct-major-twelfth, ct-double-octave) !default;

// Class names to be used when generating CSS
$ct-class-chart: ct-chart !default;
$ct-class-chart-line: ct-chart-line !default;
$ct-class-chart-bar: ct-chart-bar !default;
$ct-class-horizontal-bars: ct-horizontal-bars !default;
$ct-class-chart-pie: ct-chart-pie !default;
$ct-class-chart-donut: ct-chart-donut !default;
$ct-class-label: ct-label !default;
$ct-class-series: ct-series !default;
$ct-class-line: ct-line !default;
$ct-class-point: ct-point !default;
$ct-class-area: ct-area !default;
$ct-class-bar: ct-bar !default;
$ct-class-slice-pie: ct-slice-pie !default;
$ct-class-slice-donut: ct-slice-donut !default;
$ct-class-grid: ct-grid !default;
$ct-class-grid-background: ct-grid-background !default;
$ct-class-vertical: ct-vertical !default;
$ct-class-horizontal: ct-horizontal !default;
$ct-class-start: ct-start !default;
$ct-class-end: ct-end !default;

// Container ratio
$ct-container-ratio: math.div(1, 1.618) !default;

// Text styles for labels
$ct-text-color: rgba(0, 0, 0, 0.4) !default;
$ct-text-size: 0.75rem !default;
$ct-text-align: flex-start !default;
$ct-text-justify: flex-start !default;
$ct-text-line-height: 1;

// Grid styles
$ct-grid-color: rgba(0, 0, 0, 0.2) !default;
$ct-grid-dasharray: 2px !default;
$ct-grid-width: 1px !default;
$ct-grid-background-fill: none !default;

// Line chart properties
$ct-line-width: 4px !default;
$ct-line-dasharray: false !default;
$ct-point-size: 10px !default;
// Line chart point, can be either round or square
$ct-point-shape: round !default;
// Area fill transparency between 0 and 1
$ct-area-opacity: 0.1 !default;

// Bar chart bar width
$ct-bar-width: 10px !default;

// Donut width (If donut width is to big it can cause issues where the shape gets distorted)
$ct-donut-width: 60px !default;

// If set to true it will include the default classes and generate CSS output. If you're planning to use the mixins you
// should set this property to false
$ct-include-classes: true !default;

// If this is set to true the CSS will contain colored series. You can extend or change the color with the
// properties below
$ct-include-colored-series: $ct-include-classes !default;

// If set to true this will include all responsive container variations using the scales defined at the top of the script
$ct-include-alternative-responsive-containers: $ct-include-classes !default;

// Series names and colors. This can be extended or customized as desired. Just add more series and colors.
$ct-series-names: (a, b, c, d) !default;
$ct-series-colors: (
    #59922b,
    #d70206,
    #0544d3,
    #BFBF3F
) !default;

$chart-tooltip-bg: #ecf2f6;
$chart-tooltip-color: #453D3F;


@mixin ct-responsive-svg-container($width: 100%, $ratio: $ct-container-ratio) {
    display: block;
    position: relative;
    width: $width;

    &:before {
        display: block;
        float: left;
        content: "";
        width: 0;
        height: 0;
        padding-bottom: $ratio * 100%;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    > svg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
}

@mixin ct-align-justify($ct-text-align: $ct-text-align, $ct-text-justify: $ct-text-justify) {
    align-items: $ct-text-align;
    justify-content: $ct-text-justify;
    // Fallback to text-align for non-flex browsers
    @if ($ct-text-justify == 'flex-start') {
        text-align: left;
    } @else if ($ct-text-justify == 'flex-end') {
        text-align: right;
    } @else {
        text-align: center;
    }
}

@mixin ct-chart-label($ct-text-color: $ct-text-color, $ct-text-size: $ct-text-size, $ct-text-line-height: $ct-text-line-height) {
    fill: $ct-text-color;
    color: $ct-text-color;
    font-size: $ct-text-size;
    line-height: $ct-text-line-height;
}

@mixin ct-chart-grid($ct-grid-color: $ct-grid-color, $ct-grid-width: $ct-grid-width, $ct-grid-dasharray: $ct-grid-dasharray) {
    stroke: $ct-grid-color;
    stroke-width: $ct-grid-width;

    @if ($ct-grid-dasharray) {
        stroke-dasharray: $ct-grid-dasharray;
    }
}

@mixin ct-chart-point($ct-point-size: $ct-point-size, $ct-point-shape: $ct-point-shape) {
    stroke-width: $ct-point-size;
    stroke-linecap: $ct-point-shape;
}

@mixin ct-chart-line($ct-line-width: $ct-line-width, $ct-line-dasharray: $ct-line-dasharray) {
    fill: none;
    stroke-width: $ct-line-width;

    @if ($ct-line-dasharray) {
        stroke-dasharray: $ct-line-dasharray;
    }
}

@mixin ct-chart-area($ct-area-opacity: $ct-area-opacity) {
    stroke: none;
    fill-opacity: $ct-area-opacity;
}

@mixin ct-chart-bar($ct-bar-width: $ct-bar-width) {
    fill: none;
    stroke-width: $ct-bar-width;
}

@mixin ct-chart-donut($ct-donut-width: $ct-donut-width) {
    fill: none;
    stroke-width: $ct-donut-width;
}

@mixin ct-chart-series-color($color) {
    .#{$ct-class-point}, .#{$ct-class-line}, .#{$ct-class-bar}, .#{$ct-class-slice-donut} {
        stroke: $color;
    }

    .#{$ct-class-slice-pie}, .#{$ct-class-area} {
        fill: $color;
    }
}

@mixin ct-chart($ct-container-ratio: $ct-container-ratio, $ct-text-color: $ct-text-color, $ct-text-size: $ct-text-size, $ct-grid-color: $ct-grid-color, $ct-grid-width: $ct-grid-width, $ct-grid-dasharray: $ct-grid-dasharray, $ct-point-size: $ct-point-size, $ct-point-shape: $ct-point-shape, $ct-line-width: $ct-line-width, $ct-bar-width: $ct-bar-width, $ct-donut-width: $ct-donut-width, $ct-series-names: $ct-series-names, $ct-series-colors: $ct-series-colors) {

    .#{$ct-class-label} {
        @include ct-chart-label($ct-text-color, $ct-text-size);
    }

    .#{$ct-class-chart-line} .#{$ct-class-label},
    .#{$ct-class-chart-bar} .#{$ct-class-label} {
        display: flex;
    }

    .#{$ct-class-chart-pie} .#{$ct-class-label},
    .#{$ct-class-chart-donut} .#{$ct-class-label} {
        dominant-baseline: central;
    }

    .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start} {
        @include ct-align-justify(flex-end, flex-start);
    }

    .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end} {
        @include ct-align-justify(flex-start, flex-start);
    }

    .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-start} {
        @include ct-align-justify(flex-end, flex-end);
    }

    .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-end} {
        @include ct-align-justify(flex-end, flex-start);
    }

    .#{$ct-class-chart-bar} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start} {
        @include ct-align-justify(flex-end, center);
    }

    .#{$ct-class-chart-bar} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end} {
        @include ct-align-justify(flex-start, center);
    }

    .#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start} {
        @include ct-align-justify(flex-end, flex-start);
    }

    .#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end} {
        @include ct-align-justify(flex-start, flex-start);
    }

    .#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-start} {
        @include ct-align-justify(center, flex-end);
    }

    .#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-end} {
        @include ct-align-justify(center, flex-start);
    }

    .#{$ct-class-grid} {
        @include ct-chart-grid($ct-grid-color, $ct-grid-width, $ct-grid-dasharray);
    }

    .#{$ct-class-grid-background} {
        fill: $ct-grid-background-fill;
    }

    .#{$ct-class-point} {
        @include ct-chart-point($ct-point-size, $ct-point-shape);
    }

    .#{$ct-class-line} {
        @include ct-chart-line($ct-line-width);
    }

    .#{$ct-class-area} {
        @include ct-chart-area();
    }

    @if $ct-include-colored-series {
        @for $i from 0 to length($ct-series-names) {
            .#{$ct-class-series}-#{nth($ct-series-names, $i + 1)} {
                $color: nth($ct-series-colors, $i + 1);

                @include ct-chart-series-color($color);
            }
        }
    }
}

@if $ct-include-classes {
    @include ct-chart();

    @if $ct-include-alternative-responsive-containers {
        @for $i from 0 to length($ct-scales-names) {
            .#{nth($ct-scales-names, $i + 1)} {
                @include ct-responsive-svg-container($ratio: nth($ct-scales, $i + 1));
            }
        }
    }
}

// legend

.ct-legend {
    position: relative;
    z-index: 10;
    list-style: none;
    text-align: center;
    padding: 0;
    margin-top: 1.5rem;

    li {
        position: relative;
        padding-left: 20px;
        margin-left: 20px;
        margin-bottom: 3px;
        cursor: pointer;
        display: inline-block;

        &:before {
            width: 12px;
            height: 12px;
            position: absolute;
            left: 0;
            top: 8px;
            content: '';
            border: 3px solid transparent;
            border-radius: 2px;
        }
    }

    @for $i from 0 to length($ct-series-colors) {
        .ct-series-#{$i}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
    }
}

.price-chart {
    padding-bottom: $default-spacing;
    padding-top: $default-spacing;
    width: 1000px;

    @include media-breakpoint-up(md) {
        width: initial;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: $default-spacing-lg;
        padding-top: $default-spacing-lg;
    }

    &-header {
        text-align: center;
        margin-bottom: 2rem;
        font-weight: 500;
        color: $text-red;
    }

    &-container {
        width: 100%;
        overflow-x: scroll;

        @include media-breakpoint-up(md) {
            overflow-x: auto;
        }
    }
}



.chartist-tooltip {
    position: absolute;
    opacity: 0;
    min-width: 5em;
    padding: .5em;
    background: $chart-tooltip-bg;
    color: $chart-tooltip-color;
    font-family: Oxygen, Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    transition: opacity .2s linear;
    border-radius: 15px;
    display: none;

    &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -15px;
        border: 15px solid transparent;
        border-top-color: $chart-tooltip-bg;
    }
    &.tooltip-show {
        opacity: 1;
        display: inline-block;
    }

    &-meta {
        color: $red;
    }
}

.ct-area, .ct-line {
    pointer-events: none;
}
