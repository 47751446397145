
a.cta {
    text-align: center;
    color: $colorDarkGray !important;
    margin: 1rem 0;
    display: inline-block;
    text-decoration: none;
    padding: 0.5rem 1rem;
    background-color: $white !important;
    border: 1px solid $colorDarkGray;
    transition: background-color 200ms linear;
    border-radius: 5px;

    &:hover {
        border: 1px solid $red;
    }
}

@mixin cta-header-btn($border-color, $background-color, $color, $hoverColor) {
    background-color: $background-color;
    border: 1px solid $border-color;
    border-radius: 5px;
    color: $color;
    display: inline-block;
    margin: 3rem 1rem 1rem;
    padding: .5rem 0.8rem;
    text-align: center;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color .2s linear;

    &:hover {
        background-color: $hoverColor;
        border: 1px solid $hoverColor;
    }
}

a.cta-header {
    @include cta-header-btn($colorDarkGray, $white, $colorDarkGray, $red);
}

a.cta-header-white-border {
    @include cta-header-btn($white, $ctaHeaderColor, $white, $red);
}

a.link-button {
    background-color: $colorDarkGray;
    border: 1px solid $colorDarkGray;
    border-radius: 5px;
    color: $white;
    display: inline-block;
    margin: 0;
    padding: .5rem 0.8rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color .2s linear;

    &:hover {
        background-color: $red;
        border: 1px solid $red;
    }
}
