
#languageDropdown {
    text-decoration: none !important;
}

.header-index {
    min-height: 35vh;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    background-position: top center;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0;

    @include media-breakpoint-up(lg) {
        min-height: 65vh;
    }

    &-header {
        text-align: center;
        font-size: $header-font-size-mobile;
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(lg) {
            font-size: $header-font-size;
            line-height: 4.5rem;
        }
    }

    &-subheader {
        text-align: center;
        font-size: $subheader-font-size-mobile;

        @include media-breakpoint-up(lg) {
            font-size: $subheader-font-size;
        }
    }

    &-cta {
        font-size: $subheader-font-size-mobile;

        @include media-breakpoint-up(lg) {
            font-size: $subheader-font-size;
        }
    }

    &-plus1 {
        .header-index-header {
            @include media-breakpoint-up(lg) {
                &:before {
                    content: '+';
                    position: absolute;
                    top: -48px;
                    left: -48px;
                }
            }
        }
    }

    &-show-subheader1 {
        .header-index-subheader {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }

    &-video-wrapper {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        z-index: -1;
        pointer-events: none;
        overflow: hidden;
        padding: 0;
        position: relative;

        > iframe {
            width: 100% !important;
            height: 39vw !important;
            position: relative !important;
            transform: unset !important;
            left: 0 !important;
            top: 0 !important;
            min-height: unset !important;
            min-width: 100% !important;
            margin-bottom: -10px !important;
        }
    }

    &-video-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        color: white;
        display: flex;
        align-content: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }
}

.navbar-light-transparent {
    background: white;
    box-shadow: 0 4px 10px -6px #000000;

    .nav-link {
        font-weight: $font-weight-semi;

        &:focus {
            outline: none !important;
        }

        &-dropdown {
            display: none;

            @include media-breakpoint-up(lg) {
                display: inline-block;
                padding: 0.5rem 0.3rem 0.5rem 0.3rem !important;
            }
            @include media-breakpoint-up(xl) {
                display: inline-block;
                padding: 0.5rem 0.4rem 0.5rem 0.4rem !important;
            }
        }

        &-mobile {
            display: block;
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    .nav-item {
        &:focus {
            outline: none !important;
        }
    }

    .dropdown-toggle-sa {
        color: #6c6c6c;
        display: none;

        @include media-breakpoint-up(lg) {
            display: inline;
            position: relative;
            left: -4px;
        }
    }

    .nav-link.show {
        text-decoration: underline;
    }

    .dropdown-small {
        padding: 10px 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 14rem;
        }
    }

    .navbar-collapse {
        flex-wrap: wrap-reverse;
    }

    .navbar-nav {
        width: 100%;
        justify-content: flex-end;
    }

    .dropdown-lang {
        min-width: 250px;
        right: 0;
        left: auto;

        .active {
            font-weight: $font-weight-bold;
        }
    }

    .dropdown-menu:not(.megamenu) {
        @include media-breakpoint-down(lg) {
            border: none;
        }
        @include media-breakpoint-up(lg) {
            box-shadow: 0 8px 10px -7px rgba(0, 0, 0, 0.5);
        }
    }
}

.navbar-toggler {
    border: none;
}

.navbar-collapse .show {
    background-color: white;
}

.navbar-subpage {
    background: white;
}

.navbar-shortlinks {
    transition: height 0.5s ease-in-out;
}

.navbar-shortlinks, .navbar-menu {
    .nav-link:not(.nav-link-dropdown) {
        @include media-breakpoint-up(lg) {
            padding: 0.5rem 0.5rem !important;
        }
        @include media-breakpoint-up(xl) {
            padding: 0.5rem 1rem !important;
        }
    }

    .nav-item:last-child {
        .nav-link {
            padding-right: 0 !important;
        }

        .dropdown-toggle-sa {
            left: 0;
        }
    }
}

.nav-link-small {
    color: #6c6c6c;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px 0;
    display: block;

    @include media-breakpoint-up(lg) {
        padding: 2px 1rem;
        margin-top: 11px;
    }
}

.navbar-menu {
    .active {
        font-weight: $font-weight-bold;
    }
}

.navbar .megamenu {
    border: none;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
}

.megamenu-container {
    background-color: white;

    @include media-breakpoint-up(lg) {
        padding-top: 2rem;
        padding-bottom: 2rem;
        box-shadow: 0 8px 10px -7px rgba(0,0,0,0.5);
        overflow: auto;
        max-height: 90vh;
    }
}

@include media-breakpoint-up(lg) {
    .navbar .has-megamenu {
        position: static !important;
    }
    .navbar .megamenu {
        left: 0;
        right: 0;
        width: 100%;
        margin-top: -23px;
    }

    .navbar .nav-item:hover .dropdown-menu {
        display: block;
        top: 100%;
        right: 0;
        left: auto;
    }
}

@include media-breakpoint-down(lg) {
    .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse {
        overflow-y: auto;
        max-height: 90vh;
        margin-top: 10px;
    }
}

.menu {
    &-main-link {
        color: $text-red;
        font-size: 1.2rem;
        text-decoration: none;
        display: block;
        margin-bottom: 1rem;

        @include media-breakpoint-up(lg) {
            font-size: $h3-font-size;
        }
    }

    &-item {
        margin-bottom: 1rem;

        &-img {
            max-width: 100%;
            height: auto;
            display: inline-block;
            margin-right: 1rem;
            filter: grayscale(100%);
        }

        &-link {
            font-size: $h4-font-size;
            text-decoration: none;
            color: $text-gray-dark;
            display: block;

            &:hover {
                color: $text-red;

                img {
                    filter: grayscale(0);
                }
            }
        }

        &-second {
            font-size: 1.2rem;
            font-weight: $font-weight-bold;

            @include media-breakpoint-up(lg) {
                border-bottom: 1px solid black;
            }
        }

        &-third {
            font-size: 1rem;
            padding: 1px 0;
        }

        &-small {

            display: block;

            &-first {
                display: block;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            &-link {
                padding: 5px 2rem;
                display: block;
                font-size: 1rem;
                color: $text-gray-dark;
                text-decoration: none;

                @include media-breakpoint-up(lg) {
                    padding: 5px 10px;
                    font-size: 1.2rem;
                }

                &:hover {
                    background-color: $gray-3;
                }
            }
        }
    }
}

.second-menu {
    background-color: $light-bg-color;

    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-link {
        padding: 2rem 1rem;
        display: inline-block;
        text-decoration: none;
        color: $text-gray-dark;
        text-align: center;
        flex: 100%;

        &:hover {
            color: $text-red;
        }

        @include media-breakpoint-up(lg) {
            flex: 1 1 auto;
        }
    }

    &-img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto 0.5rem;
        min-width: 40px;
    }

    &-active {
        background-color: $text-red;
        color: white;
        .second-menu-img {
            filter: brightness(0) invert(1);
        }

        &:hover {
            color: white;
        }
    }
}

.navbar-brand > img {
    width: 150px;
    transition: all 0.2s ease-in;

    @include media-breakpoint-up(lg) {
        width: 185px;
    }
}

.scrolling {
    @include media-breakpoint-up(lg) {
        .navbar-shortlinks {
            height: 0;

            > * {
                display: none;
            }
        }

        .navbar-brand > img {
            width: 120px;
        }
    }
}

@media print {
    .navbar-light-transparent {
        display: none;
    }

    .header-index-video-wrapper {
        display: none;
    }

    footer {
        display: none;
    }
}
