
/*
 default FSC styles
 these styles need to be adjusted when
 Configuration/TypoScript/Constants/Plugin/0.fsc.constants.typoscript
 is adjusted
 */
$ce-borderWidth: 2px; // styles.content.textmedia.borderWidth
$ce-borderColor: black; // styles.content.textmedia.borderColor
$ce-borderPadding: 0; // styles.content.textmedia.borderPadding
$ce-textMargin: 10px; // styles.content.textmedia.textMargin
$ce-columnSpacing: 10px; // styles.content.textmedia.columnSpacing
$ce-rowSpacing: 10px; // styles.content.textmedia.rowSpacing

$gray-dark: #333333;
$gray: #6e6e6e;
$gray-light: #9c9c9c;
$gray-lightest: #d9dada;
$light-bg-color: #f5f5f5;

$gray-2: #d8d8d8;
$gray-3: #e8e8e8;

$yellow: rgb(255, 255, 0);
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$red: rgb(194, 0, 18);
$green: #28a745;
$cyan: #17a2b8;

$text-red: #c20012;
$text-gray-light: rgb(217, 218, 218);
$text-gray-timeline: rgb(156, 156, 156);
$text-gray-dark: $gray-dark;

$primary:       $red;
$secondary:     $text-gray-light;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-light;
$dark:          $gray-dark;

$var-green1: $red;
$var-blue1: $red;
$var-border-radius: 0;

$font-family-base:
    'Akkurat',
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-weight-semi: 500;
$font-weight-bold: 600;

$font-family-sans-serif: $font-family-base;
$header-font-size: 68px;
$header-font-size-mobile: 30px;
$subheader-font-size: 24px;
$subheader-font-size-mobile: 20px;

$h1-font-size: 30px;
$h2-font-size: 28px;
$h3-font-size: 26px;
$h4-font-size: 24px;
$h5-font-size: 22px;
$h6-font-size: 20px;

$font-size-text: 19px;
$font-size-small: 16px;

$font-size-base: 1.2rem;
$grid-columns: 12;
$grid-gutter-width: 4rem;
$lead-font-size: 1.2rem;

$timeline-line-color: $text-red;
$anniversary-bg: $gray-light;
$anniversary-font-color: $black;
$default-spacing-lg: 5rem;
$default-spacing: 3rem;
$default-spacing-sm: 1rem;

$navbar-light-color:                $gray;
$navbar-light-hover-color:          darken($navbar-light-color, 0.7);
$navbar-light-active-color:         darken($navbar-light-color, 0.7);
$navbar-light-disabled-color:       lighten($navbar-light-color, 0.7);

// hotspot variables
$buttonRadius: 24px;
$labelPaddingX: $buttonRadius * 1;
$labelBorderRadius: 2px;
$labelFade: 0.5s;
$colorButton: $text-red;
$colorButtonHover: darken($text-red, 20%);
$ctaHeaderColor: #8f000d;
$colorText: #333;
$colorHeadingBackground: #555;
$colorDarkGray: #212529;

$input-padding-x: 1.5rem;
$form-floating-padding-y: 0.8rem;

$btn-border-radius: 30px;

$accordion-button-active-bg: $light-bg-color;

$enable-dark-mode: false;

$input-bg: $light-bg-color;
$table-bg: rgba(0,0,0,0);
