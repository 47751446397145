main div[id^=c] {
    scroll-margin-top: 100px;
}

.ce-table {
	td, th {
		vertical-align: top;
	}
}

.ce-textpic,
.ce-image,
.ce-nowrap .ce-bodytext {
	overflow: hidden;
}

.ce-center {
	.ce-outer {
		position: relative;
		float: right;
		right: 50%;
	}
	.ce-inner {
		position: relative;
		float: right;
		right: -50%;
	}
}

.ce-gallery {
	overflow: hidden;

	figure {
		display: table;
		margin: 0;
	}
	figcaption {
		display: table-caption;
		caption-side: bottom;
	}
	img {
		display: block;
	}
	iframe {
		border-width: 0;
	}

	.ce-left & {
		float: left;
	}
	.ce-right & {
		float: right;
	}
	.ce-intext.ce-right &, .ce-intext.ce-left &, .ce-above & {
		margin-bottom: $ce-textMargin;
	}
	.ce-intext.ce-right & {
		margin-left: $ce-textMargin;
	}
	.ce-intext.ce-left & {
		margin-right: $ce-textMargin;
	}
	.ce-below & {
		margin-top: $ce-textMargin;
	}
}

.ce-border {
	img, iframe {
		border: $ce-borderWidth solid $ce-borderColor;
		padding: $ce-borderPadding;
	}
}

.ce-column {
	float: left;
	margin-right: $ce-columnSpacing;
	&:last-child {
		margin-right: 0;
	}
}

.ce-row {
	overflow: hidden;
	margin-bottom: $ce-rowSpacing;
	&:last-child {
		margin-bottom: 0;
	}
}

.ce-above .ce-bodytext {
	clear: both;
}

.ce-intext.ce-left {
	ol, ul {
		padding-left: 40px;
		overflow: auto;
	}
}

.ce-headline-left {
	text-align: left;
}

.ce-headline-center {
	text-align: center;
}

.ce-headline-right {
	text-align: right;
}

.ce-uploads {
	margin: 0;
	padding: 0;

	li, div {
		overflow: hidden;
	}
	li {
		list-style: none outside none;
		margin: 1em 0;
	}
	img {
		float: left;
		padding-right: 1em;
		vertical-align: top;
	}
	span {
		display: block;
	}
}

.frame-space-before-extra-small {
	margin-top: 1em;
}

.frame-space-before-small {
	margin-top: 2em;
}

.frame-space-before-medium {
	margin-top: 3em;
}

.frame-space-before-large {
	margin-top: 4em;
}

.frame-space-before-extra-large {
	margin-top: 5em;
}

.frame-space-after-extra-small {
	margin-bottom: 1em;
}

.frame-space-after-small {
	margin-bottom: 2em;
}

.frame-space-after-medium {
	margin-bottom: 3em;
}

.frame-space-after-large {
	margin-bottom: 4em;
}

.frame-space-after-extra-large {
	margin-bottom: 5em;
}

.frame-ruler-before:before {
	content: '';
	display: block;
	border-top: 1px solid rgba(0, 0, 0, 0.25);
	margin-bottom: 2em;
}

.frame-ruler-after:after {
	content: '';
	display: block;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	margin-top: 2em;
}

.frame-indent {
	margin-left: 15%;
	margin-right: 15%;
}

.frame-indent-left {
	margin-left: 33%;
}

.frame-indent-right {
	margin-right: 33%;
}

.text {
    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }
}

h1 {
    font-size: $h1-font-size;
    font-weight: $font-weight-semi;
}

h2 {
    font-size: $h2-font-size;
    font-weight: $font-weight-semi;
}

h3 {
    font-size: $h3-font-size;
    font-weight: $font-weight-semi;
}

h4 {
    font-size: $h4-font-size;
}

.font-small {
    font-size: $font-size-small;
}

.video-container {
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;

    @include media-breakpoint-up(lg) {
        padding-top: $default-spacing-lg;
        padding-bottom: $default-spacing-lg;
    }
}

.default-spacing {
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;

    @include media-breakpoint-up(lg) {
        padding-top: $default-spacing-lg;
        padding-bottom: $default-spacing-lg;
    }

    &-0 {
        padding-top: $default-spacing;
        padding-bottom: $default-spacing;
    }

    &-1 {
        padding-top: $default-spacing-lg;
        padding-bottom: $default-spacing-lg;
    }

    &-2 {
        padding-top: $default-spacing-sm;
        padding-bottom: $default-spacing-sm;
    }
}

.bg-white {
    color: $text-gray-dark;
}

.bg-0 {
    background-color: $white;
    color: $text-gray-dark;
}

.bg-1 {
    background-color: $light-bg-color;
    color: $black;
}

.bg-2 {
    background-color: $gray-2;
    color: $black;
}

.bg-3 {
    background-color: $gray-3;
    color: $black;
}

.bg-4 {
    background-color: $colorDarkGray;
    color: $white;
}

.frame-type-list > header {
    h1, h2, h3, h4, h5 {
        color: $text-red;
        text-align: center;
    }
}

.table-header {
    color: $text-red;
    font-weight: $font-weight-semi;
    border-top: 1px solid $text-red;
    border-bottom: 1px solid $text-red;
}

.table {
    thead + tbody {
        border-top-color: $text-red !important;
    }
}

.color-red { color: $text-red }

.text-small {
    font-size: 1.0rem;
}

.text-medium {
    font-size: 1.1rem;
}

.text-large {
    font-size: 1.3rem;
}

.text-xl {
    font-size: 2rem;
    line-height: 1;

    @include media-breakpoint-up(lg) {
        font-size: 3rem;
    }
}

.text-xxl {
    font-size: 2.5rem;
    line-height: 0.6;

    @include media-breakpoint-up(lg) {
        font-size: 3.8rem;
    }
}

.text-element {
    word-break: break-word;
}

.table-responsive {
    word-break: normal;
}

.tx_solr {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.klaro .cookie-modal {
    z-index: 2000 !important;
}

.cookie-settings {
    color: white;
    border: none;
    font-size: 1.1rem;
    background: none;
}

.accordion-body {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

body.disable-klaro {
    .cookie-settings {
        display: none;
    }
}
